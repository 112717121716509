@font-face {
  font-family: "Gilroy-Light";
  src: url("../../assets/fonts/Gilroy-Light.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("../../assets/fonts/Gilroy-ExtraBold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Gilroy-Black";
  src: url("../../assets/fonts/Gilroy-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../../assets/fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: 500;
}

.cws-light-font {
  font-family: "Gilroy-Light", -apple-system, BlinkMacSystemFont, Segoe UI,		Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,		sans-serif;
}

.cws-bold-font {
  font-family: "Gilroy-Bold", -apple-system, BlinkMacSystemFont, Segoe UI,		Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,		sans-serif;
}

.cws-black-font {
  font-family: "Gilroy-Black", -apple-system, BlinkMacSystemFont, Segoe UI,		Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,		sans-serif;
}

.cws-extra-bold-font {
  font-family: "Gilroy-ExtraBold", -apple-system, BlinkMacSystemFont, Segoe UI,		Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,		sans-serif;
}

.lds-ring2 {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring2 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #D4165E;
  border-radius: 50%;
  animation: lds-ring 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #D4165E transparent transparent transparent;
}

.lds-ring2 div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring2 div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring2 div:nth-child(3) {
  animation-delay: -0.15s;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.force-height-zero {
  height: 0 !important;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.ripple {
  border-radius: 50%;
  animation: ripple 1.3s linear infinite;
}

.ripple::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  animation-delay: 1s;
}

.ripple::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  animation: inherit;
  animation-delay: 1s;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0.7rem rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 3rem rgba(0, 0, 0, 0);
  }
}

.cws-height-10 {
  height: 10vh;
}

.cws-height-20 {
  height: 20vh;
}

.cws-height-30 {
  height: 30vh;
}

.cws-height-40 {
  height: 40vh;
}

.cws-height-48 {
  height: 48%;
}

.cws-height-50 {
  height: 50vh;
}

.cws-height-60 {
  height: 60vh;
}

.cws-height-70 {
  height: 70vh;
}

.cws-height-80 {
  height: 80vh;
}

.cws-height-90 {
  height: 90vh;
}

.cws-primary-color {
  background-color: #faf1f3;
}

.cws-booking-form-bg {
  background-color: #eeeeee;
}

.cws-width-10 {
  width: 10%;
}

.cws-width-20 {
  width: 20%;
}

.cws-width-30 {
  width: 30%;
}

.cws-width-40 {
  width: 40%;
}

.cws-width-45 {
  width: 45%;
}

.cws-width-47 {
  width: 47%;
}

.cws-width-50 {
  width: 50%;
}

.cws-width-55 {
  width: 55%;
}

.cws-width-60 {
  width: 60%;
}

.cws-width-70 {
  width: 70%;
}

.cws-width-80 {
  width: 80%;
}

.cws-width-90 {
  width: 90%;
}

*, html, body {
  font-family: "Gilroy-Light", -apple-system, BlinkMacSystemFont, Segoe UI,		Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,		sans-serif;
}

html {
  width: 100vw;
  overflow-x: hidden;
}

@media (max-width: 600px) {
  .mobile-padding {
    padding: 0 20px !important;
  }
}

@media (max-width: 600px) {
  .mobile-padding2 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

.cws-container {
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
}

@media (min-width: 420px) {
  .cws-container {
    padding: 0 20px;
  }
}

@media (min-width: 640px) {
  .cws-container {
    padding: 0 25px;
  }
}

@media (min-width: 992px) {
  .cws-container {
    width: 940px;
    padding: 0 16px;
  }
}

@media (min-width: 1024px) {
  .cws-container {
    width: 992px;
    padding: 0 16px;
  }
}

@media (min-width: 1200px) {
  .cws-container {
    width: 1080px;
    padding: 0 0px;
  }
}

.cws-select {
  appearance: none;
  cursor: pointer;
}

.cws-select-triangle {
  height: 8px;
  width: 15px;
  background: #ff0000;
  display: block;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  margin: -2px 0 5px 0;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.cws-select-triangle2 {
  height: 8px;
  width: 15px;
  background: #ff0000;
  display: block;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  margin: -2px 0 5px 0;
  top: 50%;
  transform: translateY(calc(-50% + 0.75rem));
  right: 35px;
}

.footer svg {
  width: 14px;
  height: 14px;
}

.cws-payment-prop svg {
  width: 60px !important;
  height: 60px !important;
}

.admin-bell svg {
  width: 25px !important;
  height: 25px !important;
}

.admin-bell span {
  top: -6px;
  right: -8px;
}

.cws-features-icon-bg {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: #facad7;
}

.cws-features-icon-bg svg {
  width: 19px;
  height: 19px;
}

.cws-social-bg-border {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: transparent;
  border: 2px solid #0091ff;
}

.cws-social-bg-border svg {
  color: #0091ff !important;
}

.cws-social-bg-border:hover {
  background-color: #0091ff;
}

.cws-social-bg-border:hover svg {
  color: #ffffff !important;
}

@media (min-width: 420px) {
  .cws-booking-hero-ml {
    margin-left: 20px;
  }
}

@media (min-width: 640px) {
  .cws-booking-hero-ml {
    margin-left: 25px;
  }
}

@media (min-width: 992px) {
  .cws-booking-hero-ml {
    margin-left: calc( ((100vw - 940px) / 2) + 16px);
  }
}

@media (min-width: 1024px) {
  .cws-booking-hero-ml {
    margin-left: calc( ((100vw - 992px) / 2) + 16px);
  }
}

@media (min-width: 1200px) {
  .cws-booking-hero-ml {
    margin-left: calc( (100vw - 1080px) / 2);
  }
}

@media (max-width: 600px) {
  .mobile-height {
    height: 60vh !important;
  }
}

@media (max-width: 600px) {
  .mobile-height2 {
    height: 75vh !important;
  }
}

.mobile-nav {
  margin-top: 64px;
  height: calc( 100vh - 64px);
}

.mobile-nav ul a {
  opacity: 0;
}

.mobile-nav.active {
  animation: fadeInLeft .7s ease-in-out forwards;
}

.mobile-nav.active ul a:nth-child(1) {
  animation: fadeInUp .2s ease-in-out .8s forwards;
}

.mobile-nav.active ul a:nth-child(2) {
  animation: fadeInUp .2s ease-in-out .9s forwards;
}

.mobile-nav.active ul a:nth-child(3) {
  animation: fadeInUp .2s ease-in-out .95s forwards;
}

.mobile-nav.active ul a:nth-child(4) {
  animation: fadeInUp .2s ease-in-out 1s forwards;
}

.mobile-nav.active ul a:nth-child(5) {
  animation: fadeInUp .2s ease-in-out 1.05s forwards;
}

.mobile-nav.active ul a:nth-child(6) {
  animation: fadeInUp .2s ease-in-out 1.10s forwards;
}

.mobile-nav.inactive {
  animation: fadeOutLeft .2s ease-in-out .6s forwards;
}

.mobile-nav.inactive ul a:nth-child(1) {
  animation: fadeOutDown .7s ease-in-out;
}

.mobile-nav.inactive ul a:nth-child(2) {
  animation: fadeOutDown .7s ease-in-out;
}

.mobile-nav.inactive ul a:nth-child(3) {
  animation: fadeOutDown .7s ease-in-out;
}

.mobile-nav.inactive ul a:nth-child(4) {
  animation: fadeOutDown .7s ease-in-out;
}

.mobile-nav.inactive ul a:nth-child(5) {
  animation: fadeOutDown .7s ease-in-out;
}

.mobile-nav.inactive ul a:nth-child(6) {
  animation: fadeOutDown .7s ease-in-out;
}

.harmburger .line1 {
  height: 2px;
  top: 0px;
}

.harmburger .line2 {
  height: 2px;
  top: 50%;
  left: 100%;
  transform: translateX(-100%);
}

.harmburger .line3 {
  height: 2px;
  top: 100%;
}

.harmburger.active .line1 {
  height: 2px;
  transform-origin: 50%;
  transform: rotate(-45deg);
  width: 80%;
  top: 50%;
}

.harmburger.active .line2 {
  height: 2px;
  background: transparent;
  top: 50%;
  left: 100%;
  transform: translateX(-100%);
}

.harmburger.active .line3 {
  height: 2px;
  width: 80%;
  top: 50%;
  transform-origin: 50%;
  transform: rotate(45deg);
}

.cws-background-image {
  background-image: url("../../assets/images/building.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.cws-background-image2 {
  background-image: url("../../assets/images/space.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.cws-background-image3 {
  background-image: url("../../assets/images/space3.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.cws-background-image4 {
  background-image: url("../../assets/images/space2.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.cws-booking-hero-radius {
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
}

.cws-booking-hero-mt {
  margin-top: 80px;
}

.cws-home-hero-height {
  height: calc( 100vh - 64px);
  margin-top: 64px;
}

.cws-red-background {
  background-color: #ff0000;
}

.cws-form-br {
  border-radius: 55px;
}

.cws-payment-width {
  max-width: 350px;
}

.cws-plan-select-width {
  min-width: 200px;
  min-height: 50px;
}

.cws-paystack-center {
  top: -12px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.cws-button-m-width {
  min-width: 96px;
  min-height: 48px;
  border-radius: 40px;
}

.cws-booking-form-input-border {
  border: 1px solid #a7afc0;
  border-radius: 4px;
}

.cws-booking-form-width {
  max-width: 700px;
}

.cws-input-width {
  max-width: 700px;
  box-shadow: 0 0px 16px rgba(0, 0, 0, 0.1);
}

.cws-button {
  min-height: 48px;
  width: 100%;
  border-radius: 4px;
}

.cws-button-plan {
  min-height: 30px;
  min-width: 96px;
  border-radius: 4px;
}

.cws-red-border {
  border: 2px solid #ff0000;
}

.cws-red-text {
  color: #ff0000;
}

.cws-box-shadow {
  border-radius: 4px;
  border-bottom-left-radius: 54px;
  border: 1px solid tranparent;
  box-shadow: 0 0px 16px rgba(0, 0, 0, 0.1);
}

.cws-box-shadow:hover {
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid #a7afc0;
}

.cws-arrow {
  transition: all 0.3s;
  position: relative;
  z-index: -1;
}

.cws-arrow:hover {
  transform: translateX(6px);
}

.admin-gradient-border {
  margin: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 4;
  background-clip: padding-box;
  border: solid 1px transparent;
  border-radius: 40px;
}

.admin-gradient-border::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0px;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  border-radius: inherit;
  border-color: transparent;
  background: linear-gradient(189.33deg, #D4165E 7.05%, #2317B1 146.49%);
}

.admin-gradient-border svg {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.admin-input-br {
  border-radius: 40px;
  max-width: 350px;
  width: 300px;
}

.admin-gradient-bg {
  background: linear-gradient(189.33deg, #D4165E 7.05%, #2317B1 146.49%);
}

.admin-gradient-bg-link {
  background: linear-gradient(178.18deg, #D4165E -13.56%, #2317B1 158.3%);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.admin-gradient-bg-text {
  background: -webkit-linear-gradient(189.33deg, #D4165E 7.05%, #2317B1 146.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.admin-gradient-bg-text2 {
  background: linear-gradient(144.12deg, #FC58B3 11.61%, #FEAE68 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.admin-dashbord-card-view {
  background: linear-gradient(178.18deg, #D4165E -13.56%, #2317B1 158.3%);
  color: #ffffff;
}

.admin-dashbord-card-view2:hover {
  background: linear-gradient(178.18deg, #D4165E -13.56%, #2317B1 158.3%);
  color: #ffffff;
}

.admin-sidebar {
  position: fixed;
  min-height: 100vh;
  overflow-y: auto;
  width: 300px;
  top: 76px;
  left: 0;
  bottom: 0;
  border-top-width: 2px;
  border-right-width: 2px;
}

@media (max-width: 600px) {
  .admin-sidebar {
    width: 0px;
    opacity: 0;
  }
}

.admin-sidebar svg {
  width: 20px;
  height: 20px;
}

.admin-sidebar21 {
  position: fixed;
  min-height: 100vh;
  transition: all .5s ease-in-out;
  overflow-y: auto;
  width: 300px;
  top: 76px;
  left: 0;
  bottom: 0;
  border-top-width: 2px;
  border-right-width: 2px;
}

@media (max-width: 600px) {
  .admin-sidebar21 {
    top: 0;
    width: 200px;
    z-index: 400;
    padding-right: 10px;
    font-size: 14px;
    transform: translateX(-100%);
  }
  .admin-sidebar21.active {
    transform: translateX(0%);
  }
}

.admin-sidebar21 svg {
  width: 20px;
  height: 20px;
}

.admin-sidebar-left-margin {
  margin-left: 300px;
  min-height: calc( 100vh - 76px);
  width: calc(100vw - 300px);
  margin-top: 76px;
}

@media (max-width: 600px) {
  .admin-sidebar-left-margin {
    margin-left: 0;
    width: 100% !important;
  }
}

.admin-scale-y-init {
  transform: translateY(-100%);
  visibility: hidden;
  height: 0;
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  opacity: 0;
  transition: transform,opacity .75s ease-in;
}

.admin-scale-y-init.active {
  transform: translateY(0);
  visibility: visible;
  height: auto;
  padding-top: 0.75rem;
  padding-left: 5rem;
  opacity: 1;
}

.admin-main-background {
  background: #FFF9FE;
}

.admin-border-gray {
  border-color: F0F0F0;
}

.admin-card2 {
  border-top-left-radius: 0px !important;
}

.admin-border-tops {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.admin-input {
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 40px;
}

.room-row .slick-slider .slick-arrow.slick-prev::before, .room-row .slick-slider .slick-arrow.slick-next::before {
  color: #000000;
}

.admin-confirm {
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
}

.admin-confirm .first-child {
  border-width: 1px;
  border-style: solid;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.admin-confirm .last-child {
  border-width: 1px;
  border-style: solid;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.admin-percent {
  position: relative;
  width: 150px;
  margin-left: -15px;
  height: 150px;
  border-radius: 50%;
}

.admin-percent svg {
  width: 135px;
  position: relative;
  z-index: 5;
  height: 135px;
}

.admin-percent svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 13;
  transform: translate(10px, 10px);
}

.admin-percent .admin-number {
  position: absolute;
  top: 0;
  left: 0;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  display: flex;
  font-size: 24px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.admin-custom-table1 {
  width: 100%;
  margin-top: 40px;
  border-spacing: 0;
  border-collapse: separate;
}

@media (max-width: 1023px) {
  .admin-custom-table1 {
    width: 900px;
  }
}

@media (max-width: 600px) {
  .admin-custom-table1 {
    width: 700px;
  }
}

.admin-custom-table1 tr th {
  text-align: center;
  padding-bottom: 35px;
}

.admin-custom-table1 tr th:first-child {
  padding-left: 40px;
  text-align: left;
}

.admin-custom-table1 tr th:last-child {
  text-align: left;
}

.admin-custom-table1 tr {
  border: 0;
  position: relative;
}

.admin-custom-table1 tr td {
  border-top: 2px solid #f0f0f0;
  text-align: center;
  border-bottom: 2px solid #f0f0f0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.admin-custom-table1 tr td:first-child {
  border: 2px solid #f0f0f0;
  border-right: none;
  padding-left: 40px;
  text-align: left;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.admin-custom-table1 tr td:last-child {
  border: 2px solid #f0f0f0;
  border-left: none;
  text-align: left;
  position: relative;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  padding-left: 10px;
}
